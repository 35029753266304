import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClaims, setUser, userClaimsSelector } from './redux/users/ducks';
import firebase from 'firebase/app';
import LoginScreen from './pages/login_screen';
import DoctorsScreen from './pages/doctors_screen';
import PatientsScreen from './pages/patients_screen';
import LoadingScreen from './pages/loading_screen';
import ClerksScreen from './pages/clerks_screen';
import PatientScreen from './pages/patient_screen';

const App = () => {
  const dispatch = useDispatch();
  const claims = useSelector(userClaimsSelector);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user !== null) {
          dispatch(setUser(user));
          user.getIdTokenResult().then((value) => dispatch(setClaims(value.claims)));
        } else {
          dispatch(setClaims({ admin: false, loading: false }));
        }
        dispatch(setUser(user));
      }),
    [dispatch]
  );

  const AuthRoute = (props) => {
    if (claims.loading) return <LoadingScreen />;

    if (props.path === '/login') {
      if (claims.admin || claims.doctor || claims.clerk) {
        return <Redirect to={'/patients'} />;
      }
    } else if (!claims.admin && !claims.doctor && !claims.clerk) {
      return <Redirect to={'/login'} />;
    }

    if (!claims.admin && (claims.clerk || claims.doctor) && ['/doctors', '/clerks'].includes(props.path)) {
      return <Redirect to={'/patients'} />;
    }

    return <Route {...props} />;
  };

  return (
    <Router>
      <Switch>
        <AuthRoute exact path={`/login`} component={LoginScreen} />
        <AuthRoute exact path={`/doctors`} component={DoctorsScreen} />
        <AuthRoute exact path={`/clerks`} component={ClerksScreen} />
        <AuthRoute exact path={`/patients`} component={PatientsScreen} />
        <AuthRoute exact path={`/patients/:id`} component={PatientScreen} />
        <Redirect from={'/'} to={'/login'} />
      </Switch>
    </Router>
  );
};

export default App;
