const INITIAL_STATE = {
  // Sample Upload { file, fileId: 'id', patientId: 'id', progress: 99, status: 'uploading' },
  uploadsMap: {},
};

export const UploadActionTypes = {
  UPLOAD_REPORT: 'UPLOAD_REPORT',
  UPLOAD_REPORT_FAILED: 'UPLOAD_REPORT_FAILED',

  UPDATE_UPLOAD_PROGRESS: 'UPDATE_UPLOAD_PROGRESS',

  CLOSE_REPORT_MESSAGE: 'CLOSE_REPORT_MESSAGE',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const uploadReport = (upload) => ({ type: UploadActionTypes.UPLOAD_REPORT, payload: { upload } });
export const updateReportProgress = (fileId, progress) => ({
  type: UploadActionTypes.UPDATE_UPLOAD_PROGRESS,
  payload: { fileId, progress },
});

export const closeUploadMessage = (fileId) => ({ type: UploadActionTypes.CLOSE_REPORT_MESSAGE, payload: { fileId } });

export const uploadsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadActionTypes.UPLOAD_REPORT: {
      const { upload } = action.payload;
      const uploadsMapCopy = { ...state.uploadsMap };
      uploadsMapCopy[upload.fileId] = upload;
      return { ...state, uploadsMap: uploadsMapCopy };
    }

    case UploadActionTypes.UPLOAD_REPORT_FAILED: {
      const uploadsMapCopy = { ...state.uploadsMap };
      uploadsMapCopy[action.payload.fileId].status = 'error';
      return { ...state, uploadsMap: uploadsMapCopy };
    }

    case UploadActionTypes.UPDATE_UPLOAD_PROGRESS: {
      const { fileId, progress } = action.payload;
      const uploadsMapCopy = { ...state.uploadsMap };
      if (progress === 100) {
        uploadsMapCopy[fileId].status = 'completed';
      }
      uploadsMapCopy[fileId].progress = progress;
      return { ...state, uploadsMap: uploadsMapCopy };
    }

    case UploadActionTypes.CLOSE_REPORT_MESSAGE: {
      const uploadsMapCopy = { ...state.uploadsMap };
      delete uploadsMapCopy[action.payload.fileId];
      return { ...state, uploadsMap: uploadsMapCopy };
    }

    case UploadActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const uploadsSelector = (state) => Object.values(state.uploads.uploadsMap);
