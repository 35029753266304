import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { uploadReport } from '../../redux/uploads/ducks';

const useStyles = makeStyles((theme) => ({
  dropZone: {
    minHeight: 'calc(100vh - 64px)',
    position: 'relative',
    width: '100%',
  },
  disableEvents: {
    '& *': {
      pointerEvents: 'none !important',
    },
  },
  overlay: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    display: 'none',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    transition: `opacity 0.5s`,
    width: '100%',
    '& *': {
      pointerEvents: 'none !important',
    },
    '& svg': {
      animation: '$motion 1s infinite  ease-in-out alternate',
      fill: '#FFF',
      fontSize: 64,
    },
    '& h3': {
      color: '#FFF',
      fontWeight: 400,
      marginTop: 8,
    },
  },
  enableOverlay: {
    display: 'flex',
    opacity: 1,
  },
  '@keyframes motion': {
    from: {
      transform: 'translateY(0px)',
    },
    to: {
      transform: 'translateY(-16px)',
    },
  },
}));

const WithDropZone = ({ id, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dragging, setDragging] = useState(false);

  const dragOver = (e) => e.preventDefault();

  const dragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    if (e.target.id === `dropzone-${id}`) setDragging(false);
  };

  const validateFile = (file) => {
    const validTypes = ['application/pdf'];
    return validTypes.indexOf(file.type) !== -1;
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        dispatch(
          uploadReport({
            file: files[i],
            fileId: Math.random().toString(36).slice(2),
            patientId: id,
            progress: 0,
            status: 'uploading',
          })
        );
      }
    }
  };
  const fileDrop = (e) => {
    e.preventDefault();
    if (dragging) setDragging(false);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  return (
    <div
      id={`dropzone-${id}`}
      className={clsx(classes.dropZone, { [classes.disableEvents]: dragging })}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDrop={fileDrop}
    >
      {children}
      <div className={clsx(classes.overlay, { [classes.enableOverlay]: dragging })}>
        <CloudUpload />
        <Typography variant={'h3'}>Drop files anywhere to upload</Typography>
      </div>
    </div>
  );
};

export default WithDropZone;
