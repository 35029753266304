import firebase from 'firebase/app';
import moment from 'moment';

export const fetchClerks = async () => firebase.firestore().collection('users').where('type', '==', 'clerk').get();

export const fetchDoctors = async () => firebase.firestore().collection('users').where('type', '==', 'doctor').get();

export const fetchPatients = async ({ phoneNumber }, latest, limit) => {
  let query = firebase.firestore().collection('users');
  if (phoneNumber) {
    query = query
      .where('phoneNumber', '>=', '+91' + phoneNumber)
      .where('phoneNumber', '<=', '+91' + phoneNumber + '\uf8ff')
      .orderBy('phoneNumber');
  }
  if (latest) query = query.startAfter(latest);
  return query.limit(limit).get();
};

export const listenPatientById = async (id, callback) =>
  firebase
    .firestore()
    .collection('users')
    .doc(id)
    .onSnapshot((snap) => {
      const data = snap.data();
      if (snap.exists) {
        data.createdAt = data.createdAt ? moment.unix(data.createdAt.seconds) : moment();
        data.updatedAt = data.updatedAt ? moment.unix(data.updatedAt.seconds) : moment();

        data.createdAt = data.updatedAt.format('DD MMM YYYY, hh:mm A');
        data.updatedAt = data.updatedAt.format('DD MMM YYYY, hh:mm A');
      }
      callback(data);
    });

// export const createAdmin = async () => {
//   const data = { phoneNumber: '7795108392', name: 'Nitesh Srivats', email: 'niteshsrivats.k@gmail.com' };
//   data.phoneNumber = `+91${data.phoneNumber}`;
//   await firebase.app().functions('asia-south1').httpsCallable('createAdmin')(data);
//   await firebase.auth().sendPasswordResetEmail(data.email);
// };

export const createClerk = async (data) => {
  data.phoneNumber = `+91${data.phoneNumber}`;
  await firebase.app().functions('asia-south1').httpsCallable('createClerk')(data);
  await firebase.auth().sendPasswordResetEmail(data.email);
};

export const createDoctor = async (data) => {
  data.phoneNumber = `+91${data.phoneNumber}`;
  await firebase.app().functions('asia-south1').httpsCallable('createDoctor')(data);
  await firebase.auth().sendPasswordResetEmail(data.email);
};

export const createPatient = async (data) => {
  data.phoneNumber = `+91${data.phoneNumber}`;
  await firebase.app().functions('asia-south1').httpsCallable('createPatient')(data);
  await firebase.auth().sendPasswordResetEmail(data.email);
};

const fileSize = (size) => {
  if (size === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export const uploadReport = async (user, upload, callback) => {
  const { file, fileId, patientId } = upload;
  const fileName = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
  const uploadName = fileName + `-${moment().format('DD-MM-yyyy')}.pdf`;
  const path = `reports/${patientId}/${uploadName}`;

  const snap = await firebase
    .firestore()
    .collection('users')
    .doc(patientId)
    .collection('reports')
    .where('ref', '==', path)
    .limit(1)
    .get();

  if (snap.docs.length !== 0) {
    throw new Error('File already exists');
  }

  const fileRef = firebase.storage().ref(path);
  const uploadTask = fileRef.put(file);
  uploadTask.on('state_changed', (snap) => {
    callback(fileId, Math.round((snap.bytesTransferred * 100) / snap.totalBytes));
  });
  await uploadTask;

  const batch = firebase.firestore().batch();
  batch.set(firebase.firestore().collection('users').doc(patientId).collection('reports').doc(), {
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    name: fileName,
    size: fileSize(file.size),
    ref: path,
    uploadedBy: {
      name: user.displayName || 'Unknown',
      uid: user.uid,
    },
  });
  batch.update(firebase.firestore().collection('users').doc(patientId), {
    reports: firebase.firestore.FieldValue.increment(1),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
  await batch.commit();
};

export const downloadReport = async (ref) => firebase.storage().ref(ref).getDownloadURL();

// Returns 1 more item than requested (Not Required Here)
export const fetchReports = async (patientId, itemsPerPage, page) =>
  firebase
    .app()
    .functions('asia-south1')
    .httpsCallable('fetchReports')({ itemsPerPage, page, uid: patientId })
    .then((res) =>
      res.data.map((report) => {
        report.createdAt = moment.unix(report.createdAt).format('dd, Do MMM YY, hh:mm A');
        return report;
      })
    );

export const removeAdmins = async (ids) =>
  firebase.app().functions('asia-south1').httpsCallable('removeAdminPrivileges')({ ids });
