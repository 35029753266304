import React from 'react';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { Close, ExpandMore } from '@material-ui/icons';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { useDispatch, useSelector } from 'react-redux';
import { closeUploadMessage, uploadsSelector } from '../../redux/uploads/ducks';

const useStyles = makeStyles((theme) => ({
  accordion: {
    padding: theme.spacing(2, 2, 1),
    position: 'absolute',
    bottom: 24,
    right: 16,
    zIndex: theme.zIndex.appBar + 1,
  },
  accordionDetails: {
    maxHeight: 100,
    overflowY: 'scroll',
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  listText: {
    fontSize: 14,
    marginRight: 12,
  },
  red: {
    color: theme.palette.secondary.main,
  },
}));

const FileUploadDisplay = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uploads = useSelector(uploadsSelector);

  if (uploads.length === 0) return <></>;

  return (
    <Paper elevation={5}>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography gutterBottom variant={'body2'}>
            Uploading {uploads.length} files
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div>
            {uploads.map((upload) => (
              <div className={classes.listItem} key={upload.fileId}>
                <Typography
                  className={clsx(classes.listText, { [classes.red]: upload.type === 'error' })}
                  variant={'body2'}
                >
                  {upload.file.name}
                </Typography>
                {upload.status === 'uploading' ? (
                  <CircularProgressWithLabel value={upload.progress} />
                ) : (
                  <IconButton onClick={() => dispatch(closeUploadMessage(upload.fileId))} style={{ padding: 8 }}>
                    <Close color={upload.status === 'error' ? 'secondary' : 'inherit'} />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default FileUploadDisplay;
