import React from 'react';
import SEO from '../components/common/SEO';
import Layout from '../components/navigation/Layout';
import { Container, Typography } from '@material-ui/core';

const NotFoundScreen = () => (
  <Layout>
    <Container maxWidth={'sm'}>
      <SEO title={'Not found'} />

      <Typography align={'center'} color={'primary'} gutterBottom style={{ marginTop: '2rem' }} variant={'h3'}>
        NOT FOUND
      </Typography>
      <Typography align={'center'} variant={'body1'}>
        You just hit a route that doesn&#39;t exist...
      </Typography>
    </Container>
  </Layout>
);

export default NotFoundScreen;
