import React, { useEffect, useState } from 'react';
import SEO from '../components/common/SEO';
import Layout from '../components/navigation/Layout';
import DoctorFormModal from '../components/users/DoctorFormModal';
import PropTypes from 'prop-types';
import { Container, Dialog, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { doctorsSelector, fetchDoctors } from '../redux/users/ducks';
import { Add, Delete, Refresh } from '@material-ui/icons';
import { setNotification } from '../redux/notifications/ducks';
import { removeAdmins } from '../services/user_service';

const DoctorsScreen = (props) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const { data, loading } = useSelector(doctorsSelector);

  useEffect(() => {
    dispatch(fetchDoctors());
  }, [dispatch]);

  const columns = [
    { field: 'name', headerName: 'Name', sortable: true, width: 200 },
    { field: 'email', headerName: 'Email', sortable: true, width: 300 },
    { field: 'phoneNumber', headerName: 'Mobile', sortable: true, width: 175 },
    { field: 'department', headerName: 'Department', sortable: true, width: 200 },
    { field: 'id', headerName: 'ID', sortable: true, width: 300 },
    { field: 'createdAt', headerName: 'Signed Up', sortable: true, width: 210 },
    { field: 'updatedAt', headerName: 'Updated At', sortable: true, width: 210 },
  ];

  return (
    <Layout>
      <SEO title={'Doctors'} />
      <Container maxWidth={'lg'}>
        <div style={{ height: '2rem' }} />
        <TableToolbar selected={selected} setOpen={setOpen} setSelected={setSelected} />
        <DataGrid
          autoHeight
          // disableColumnMenu
          checkboxSelection
          columns={columns}
          hideFooterPagination
          // hideFooterSelectedRowCount
          loading={loading}
          // onRowClick={(params) => onRowSelected(params)}
          // onPageChange={(params) => setPage(params.page)}
          // onPageSizeChange={(params) => setRowsPerPage(params.pageSize)}
          onSelectionModelChange={(param) => setSelected(param.selectionModel)}
          page={0}
          pageSize={100}
          rows={data}
          rowCount={data.length}
          rowsPerPageOptions={[100]}
          scrollbarSize={70}
          selectionModel={selected}
        />
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)} scroll={'paper'}>
        <DoctorFormModal setOpen={setOpen} />
      </Dialog>
    </Layout>
  );
};

const TableToolbar = ({ selected, setOpen, setSelected }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <Toolbar style={{ marginBottom: 8, padding: 0 }}>
      <Tooltip title={'Refresh'}>
        <span>
          <IconButton
            aria-label={'refresh'}
            disabled={Boolean(selected.length)}
            onClick={() => dispatch(fetchDoctors())}
          >
            <Refresh />
          </IconButton>
        </span>
      </Tooltip>

      <Typography style={{ flex: '1 1 100%' }} variant={'h3'}>
        Doctors
      </Typography>

      <Tooltip title={'Add'}>
        <span>
          <IconButton aria-label={'add'} disabled={Boolean(selected.length)} onClick={() => setOpen(true)}>
            <Add />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={'Delete'}>
        <span>
          <IconButton
            aria-label={'delete'}
            disabled={!Boolean(selected.length)}
            onClick={async () => {
              if (loading) return;
              setLoading(true);
              try {
                await removeAdmins(selected);
                setSelected([]);
                dispatch(fetchDoctors());
              } catch (e) {
                console.warn(e);
                dispatch(setNotification(e.message, 'error'));
              } finally {
                setLoading(false);
              }
            }}
          >
            <Delete />
          </IconButton>
        </span>
      </Tooltip>
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default DoctorsScreen;
