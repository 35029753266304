import React from 'react';
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import clsx from 'clsx';
import {
  Accessible,
  ChevronLeft as ChevronLeftIcon,
  LocalHospital,
  Menu as MenuIcon,
  Person,
} from '@material-ui/icons';
import Notification from '../common/Notification';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutUser, userClaimsSelector } from '../../redux/users/ducks';
import FileUploadDisplay from '../common/FileUploadDisplay';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    paddingLeft: 4,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    width: drawerWidth,
    '& > div': {
      overflowX: 'hidden',
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 57,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
}));

const HeaderIcon = ({ type }) => {
  if (type === 'Patients') {
    return <Accessible />;
  } else if (type === 'Doctors') {
    return <LocalHospital />;
  } else if (type === 'Clerks') {
    return <Person />;
  }
  return <Person />;
};

HeaderIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

const Layout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const claims = useSelector(userClaimsSelector);

  return (
    <div style={{ display: 'flex' }}>
      <AppBar
        position={'fixed'}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label={'open drawer'}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            color={'inherit'}
            disableRipple
            edge={'start'}
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          {/*<div style={{ margin: '0 auto' }} />*/}
          <Button
            onClick={() => dispatch(signOutUser())}
            style={{ borderColor: 'white', color: 'white', marginLeft: 'auto' }}
            variant={'outlined'}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={'permanent'}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Patients', 'Doctors', 'Clerks']
            .slice(0, claims.admin ? 3 : claims.doctor || claims.clerk ? 1 : 0)
            .map((text) => (
              <ListItem component={Link} key={text} to={`/${text.toLowerCase()}`}>
                <ListItemIcon style={{ margin: '4px 0' }}>
                  <HeaderIcon type={text} />
                </ListItemIcon>
                {open && <ListItemText primary={text} />}
              </ListItem>
            ))}
        </List>
        <Divider />
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        <Notification />
        <FileUploadDisplay />
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
