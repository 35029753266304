import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@material-ui/core';

const CircularProgressWithLabel = ({ value }) => {
  return (
    <div style={{ display: 'inline-flex', position: 'relative' }}>
      <CircularProgress value={value} variant={'determinate'} />
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Typography color={'textSecondary'} component={'div'} variant={'caption'}>
          {value}%
        </Typography>
      </div>
    </div>
  );
};

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;
