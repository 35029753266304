import { call, put, select, takeLatest } from 'redux-saga/effects';
import { latestPatientSelector, UserActionTypes } from './ducks';
import { forgotPassword, signInWithEmailAndPassword, signOut } from '../../services/auth_service';
import { getAuthErrorMessage } from '../../utils/auth_utils';
import { NotificationActionTypes } from '../notifications/ducks';
import { fetchClerks, fetchDoctors, fetchPatients, removeAdmins } from '../../services/user_service';
import moment from 'moment';

// TODO Error Messages

export function* usersSaga() {
  yield takeLatest(UserActionTypes.SIGN_OUT_USER, signOutUser);
  yield takeLatest(UserActionTypes.LOGIN_USER, loginUserSaga);
  yield takeLatest(UserActionTypes.FORGOT_PASSWORD_USER, forgotPasswordUserSaga);

  yield takeLatest(UserActionTypes.SET_CLAIMS, setUserClaimsSaga);
  yield takeLatest(UserActionTypes.SET_USER, setUserSaga);

  yield takeLatest(UserActionTypes.FETCH_CLERKS, fetchClerksSaga);
  yield takeLatest(UserActionTypes.FETCH_DOCTORS, fetchDoctorsSaga);
  yield takeLatest(UserActionTypes.FETCH_PATIENTS, fetchPatientsSaga);

  yield takeLatest(UserActionTypes.REMOVE_ADMINS, removeAdminsSaga);
}

function* signOutUser(action) {
  try {
    yield call(signOut);
    yield put({ type: 'CLEAR_STATE' });
  } catch (e) {
    yield put({
      type: NotificationActionTypes.SET_NOTIFICATION,
      payload: { message: getAuthErrorMessage(e.code), type: 'error', show: true },
    });
  }
}

function* loginUserSaga(action) {
  const { email, password } = action.payload;
  try {
    yield call(signInWithEmailAndPassword, email, password);
  } catch (e) {
    console.error(e);
    yield put({
      type: NotificationActionTypes.SET_NOTIFICATION,
      payload: { message: getAuthErrorMessage(e.code), type: 'error', show: true },
    });
  }
}

function* forgotPasswordUserSaga(action) {
  try {
    yield call(forgotPassword, action.payload.email);
  } catch (e) {
    yield put({
      type: NotificationActionTypes.SET_NOTIFICATION,
      payload: { message: getAuthErrorMessage(e.code), type: 'error', show: true },
    });
  }
}

function* setUserClaimsSaga(action) {
  yield put({ type: UserActionTypes.SET_CLAIMS_SUCCEEDED, payload: { ...action.payload } });
}

function* setUserSaga(action) {
  yield put({ type: UserActionTypes.SET_USER_SUCCEEDED, payload: { ...action.payload } });
}

const formatDocDates = (doc) => {
  const data = doc.data();
  data.createdAt = moment.unix(data.createdAt.seconds).format('DD MMM YYYY, hh:mm A');
  data.updatedAt = moment.unix(data.updatedAt.seconds).format('DD MMM YYYY, hh:mm A');
  return data;
};

function* fetchClerksSaga(action) {
  let clerks = [];

  try {
    const snap = yield call(fetchClerks);
    if (snap.size !== 0) {
      clerks = snap.docs.map((doc) => formatDocDates(doc));
    }
    yield put({ type: UserActionTypes.FETCH_CLERKS_SUCCEEDED, payload: { clerks } });
  } catch (e) {
    console.warn(e);
    yield put({ type: NotificationActionTypes.SET_NOTIFICATION, payload: { message: e.message, type: 'error' } });
  }
}

function* fetchDoctorsSaga(action) {
  let doctors = [];

  try {
    const snap = yield call(fetchDoctors);
    if (snap.size !== 0) {
      doctors = snap.docs.map((doc) => formatDocDates(doc));
    }
    yield put({ type: UserActionTypes.FETCH_DOCTORS_SUCCEEDED, payload: { doctors } });
  } catch (e) {
    console.warn(e);
    yield put({ type: NotificationActionTypes.SET_NOTIFICATION, payload: { message: e.message, type: 'error' } });
  }
}

function* fetchPatientsSaga(action) {
  const { append, filters, limit } = action.payload;
  let latest = append ? yield select(latestPatientSelector) : undefined;
  let patients = [];

  try {
    const snap = yield call(fetchPatients, filters, latest, limit);
    if (snap.size !== 0) {
      patients = snap.docs.map((doc) => formatDocDates(doc));
      latest = snap.docs.slice(-1).pop();
    }
    yield put({
      type: UserActionTypes.FETCH_PATIENTS_SUCCEEDED,
      payload: { append, canFetch: snap.size === limit, filters, latest, patients },
    });
  } catch (e) {
    console.warn(e);
    yield put({ type: NotificationActionTypes.SET_NOTIFICATION, payload: { message: e.message, type: 'error' } });
  }
}

function* removeAdminsSaga(action) {
  try {
    yield call(removeAdmins, action.payload.data);
    yield put({ type: UserActionTypes.FETCH_DOCTORS });
    yield put({ type: UserActionTypes.FETCH_CLERKS });
  } catch (e) {
    console.warn(e);
    yield put({ type: NotificationActionTypes.SET_NOTIFICATION, payload: { message: e.message, type: 'error' } });
  }
}
