import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from './styles/theme';
import store from './redux/store';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

firebase.initializeApp({
  apiKey: 'AIzaSyBZmXfbIlvJn0iuU7OCfOKJtF_G-mPJDAM',
  authDomain: 'medical-point.firebaseapp.com',
  databaseURL: 'https://medical-point.firebaseio.com',
  projectId: 'medical-point',
  storageBucket: 'medical-point.appspot.com',
  messagingSenderId: '908572609734',
  appId: '1:908572609734:web:eaeec32acb2b24585d1bc7',
  measurementId: 'G-WP5K6W0HDY',
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);
