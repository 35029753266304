import Logo200Png from '../../assets/images/logo/png/logo-200w.png';
import Logo200Webp from '../../assets/images/logo/webp/logo-200w.webp';

const LogoImageSources = {
  metadata: { alt: 'Medical Point Logo' },
  sources: [
    {
      minWidth: 0,
      size: 200,
      png: Logo200Png,
      webp: Logo200Webp,
    },
  ],
};

export default LogoImageSources;
