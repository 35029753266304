import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes';

const primary = `#0093DE`;
const secondary = `#D7261A`;
const textPrimary = `#212121`;
const textSecondary = `#616161`;
const white = `#FFF`;

const lineHeight = 1.5;
const fontFamily = `'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', sans-serif`;

const theme = responsiveFontSizes(
  createMuiTheme({
    overrides: {
      MuiListItemText: {
        primary: {
          color: textPrimary,
        },
      },
      MuiSelect: {
        root: {
          fontSize: '14px !important',
        },
        select: {
          '&:focus': {
            backgroundColor: white,
          },
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '14px !important',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: '14px',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '14px',
        },
      },
      MuiInputBase: {
        root: {
          fontSize: '14px',
        },
      },
      MuiDataGrid: {
        root: {
          '& .MuiDataGrid-cell': {
            // maxHeight: 'none !important',
            // overflow: 'unset !important',
            // textOverflow: 'unset !important',
            // overflowWrap: 'break-word',
            // whiteSpace: 'unset !important',
          },
          '& .MuiDataGrid-row': {
            // maxHeight: 'none !important',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            fontFamily: fontFamily,
          },
          a: {
            cursor: 'pointer',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
              textUnderlinePosition: 'under',
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    palette: {
      primary: {
        main: primary,
        contrastText: white,
      },
      secondary: {
        main: secondary,
        contrastText: white,
      },
      text: {
        primary: textPrimary,
        secondary: textSecondary,
      },
    },
    typography: {
      fontFamily: fontFamily,
      h1: {
        color: textPrimary,
        fontSize: `2rem`,
        fontWeight: `700`,
      },
      h2: {
        color: textPrimary,
        fontSize: `1.5rem`,
        fontWeight: `700`,
      },
      h3: {
        color: textPrimary,
        fontSize: `1.25rem`,
        fontWeight: `600`,
      },
      body2: {
        color: textSecondary,
        fontSize: `1rem`,
        fontWeight: `400`,
        lineHeight: lineHeight,
      },
    },
  })
);

export default theme;
