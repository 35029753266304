import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { CloudDownload, Description } from '@material-ui/icons';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import { downloadReport } from '../../services/user_service';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    '& p': {
      fontSize: 14,
    },
  },
}));

const ReportsList = ({ page, pages, params, patientId, reports }) => {
  const classes = useStyles();
  const history = useHistory();

  if (reports === null) {
    return <CircularProgress size={50} />;
  }

  if (isNaN(page)) {
    const newParams = new URLSearchParams(params.toString());
    newParams.set('page', '1');
    history.push(`/patients/${patientId}?${newParams.toString()}`);
    return (
      <Typography style={{ marginTop: '1rem' }} variant={'body2'}>
        Invalid Page. Return to
      </Typography>
    );
  }

  const handleDownload = async (report) => {
    const url = await downloadReport(report.ref);
    window.open(url, '_blank');
  };

  return (
    <>
      <List dense>
        {reports.length === 0 && (
          <Typography align={'center'} style={{ margin: '4rem 0 4rem' }} variant={'h3'}>
            No reports uploaded yet. Upload now.
          </Typography>
        )}
        {reports.map((report, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: 'white' }}>
                <Description style={{ fill: '#616161' }} fontSize={'large'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              className={classes.listItemText}
              primary={`${report.name}`}
              secondary={`By ${report.uploadedBy.name} on ${report.createdAt}`}
            />
            <ListItemSecondaryAction>
              <IconButton aria-label={'download'} edge={'end'} onClick={() => handleDownload(report)}>
                <CloudDownload color={'secondary'} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <Pagination
        color={'primary'}
        boundaryCount={1}
        siblingCount={1}
        page={page}
        // count={page + (hasMore ? 1 : 0)}
        count={pages}
        style={{ marginTop: '1rem' }}
        renderItem={(item) => {
          const newParams = new URLSearchParams(params.toString());
          newParams.set('page', item.page.toString());
          return <PaginationItem component={Link} to={`/patients/${patientId}?${newParams.toString()}`} {...item} />;
        }}
      />
    </>
  );
};

ReportsList.propTypes = {
  // hasMore: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      ref: PropTypes.string.isRequired,
      uploadedBy: {
        name: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
      },
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ReportsList;
