const INITIAL_STATE = {
  user: undefined,
  claims: { loading: true },
  clerks: {
    data: [],
    loading: false,
  },
  doctors: {
    data: [],
    loading: false,
  },
  patients: {
    data: [],
    canFetch: true,
    filters: { phoneNumber: '' },
    latest: null,
    loading: false,
  },
};

export const UserActionTypes = {
  LOGIN_USER: 'LOGIN_USER',
  FORGOT_PASSWORD_USER: 'FORGOT_PASSWORD_USER',
  SIGN_OUT_USER: 'SIGN_OUT_USER',

  SET_USER: 'SET_USER',
  SET_USER_SUCCEEDED: 'SET_USER_SUCCEEDED',
  SET_CLAIMS: 'SET_CLAIMS',
  SET_CLAIMS_SUCCEEDED: 'SET_CLAIMS_SUCCEEDED',

  FETCH_CLERKS: 'FETCH_CLERKS',
  FETCH_CLERKS_SUCCEEDED: 'FETCH_CLERKS_SUCCEEDED',

  FETCH_DOCTORS: 'FETCH_DOCTORS',
  FETCH_DOCTORS_SUCCEEDED: 'FETCH_DOCTORS_SUCCEEDED',

  FETCH_PATIENTS: 'FETCH_PATIENTS',
  FETCH_PATIENTS_SUCCEEDED: 'FETCH_PATIENTS_SUCCEEDED',

  REMOVE_ADMINS: 'REMOVE_ADMINS',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const signOutUser = () => ({ type: UserActionTypes.SIGN_OUT_USER });
export const loginUser = (email, password) => ({ type: UserActionTypes.LOGIN_USER, payload: { email, password } });

export const setUser = (user) => ({ type: UserActionTypes.SET_USER, payload: { user } });
export const setClaims = (claims) => ({ type: UserActionTypes.SET_CLAIMS, payload: { claims } });

export const fetchClerks = () => ({ type: UserActionTypes.FETCH_CLERKS });
export const fetchDoctors = () => ({ type: UserActionTypes.FETCH_DOCTORS });
export const fetchPatients = (append, filters, limit) => ({
  type: UserActionTypes.FETCH_PATIENTS,
  payload: { append, filters, limit },
});

export const removeAdmins = (ids) => ({ type: UserActionTypes.REMOVE_ADMINS, payload: { data: ids } });

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USER_SUCCEEDED:
      return { ...state, user: action.payload.user };

    case UserActionTypes.SET_CLAIMS_SUCCEEDED:
      return { ...state, claims: action.payload.claims };

    case UserActionTypes.FETCH_DOCTORS:
      return { ...state, doctors: { ...state.doctors, loading: true } };

    case UserActionTypes.FETCH_DOCTORS_SUCCEEDED:
      return { ...state, doctors: { ...state.doctors, data: action.payload.doctors, loading: false } };

    case UserActionTypes.FETCH_CLERKS:
      return { ...state, clerks: { ...state.clerks, loading: true } };

    case UserActionTypes.FETCH_CLERKS_SUCCEEDED:
      return { ...state, clerks: { ...state.clerks, data: action.payload.clerks, loading: false } };

    case UserActionTypes.FETCH_PATIENTS:
      return { ...state, patients: { ...state.patients, loading: true } };

    case UserActionTypes.FETCH_PATIENTS_SUCCEEDED: {
      const { append, canFetch, filters, latest, patients } = action.payload;
      return {
        ...state,
        patients: {
          ...state.patients,
          canFetch,
          data: append ? [...state.patients.data, ...patients] : patients,
          filters,
          latest,
          loading: false,
        },
      };
    }

    case UserActionTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const userSelector = (state) => state.users.user;
export const userClaimsSelector = (state) => state.users.claims;
export const isAuthenticating = (state) => Boolean(state.users.claims.loading);

export const clerksSelector = (state) => state.users.clerks;
export const doctorsSelector = (state) => state.users.doctors;
export const patientsSelector = (state) => state.users.patients;
export const latestPatientSelector = (state) => state.users.patients.latest;
