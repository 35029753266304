import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { nameMatch, phoneNumberMatch } from '../../utils/regex_utils';
import { createDoctor } from '../../services/user_service';
import { fetchDoctors } from '../../redux/users/ducks';
import PropTypes from 'prop-types';
import { setNotification } from '../../redux/notifications/ducks';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: '2rem',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridGap: '1rem',
      gridTemplateColumns: '1fr',
    },
  },
  field: {
    minWidth: 225,
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '4px',
    alignItems: 'flex-end',
    '& label': {
      alignSelf: 'flex-end',
      paddingBottom: theme.spacing(1),
    },
  },
  description: {
    gridColumn: '1 / span 2',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1',
    },
  },
}));

const DoctorFormModal = ({ setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [department, setDepartment] = useState('');

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ name: false, phoneNumber: false });

  const departments = [
    'Dermatology',
    'Endocrinology',
    'Gastroenterology',
    'Hematology',
    'Infertility Clinic',
    'Neurology',
    'Oncology',
    'Psychiatry',
    'Pulmonology',
    'Rheumatology',
  ];

  const validate = () => {
    const nameMatchError = !nameMatch(name);
    const phoneNumberMatchError = !phoneNumberMatch(phoneNumber);
    setErrors({ name: nameMatchError, phoneNumber: phoneNumberMatchError });

    return !(nameMatchError || phoneNumberMatchError);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        await createDoctor({
          name,
          email,
          phoneNumber,
          department,
        });
        dispatch(fetchDoctors());
      } catch (e) {
        dispatch(setNotification(e.message, 'error'));
        console.warn(e);
      } finally {
        setLoading(false);
      }
      setOpen(false);
    }
  };

  return (
    <form autoComplete={'off'} name={'Doctor Form'} onSubmit={onSubmit}>
      <DialogTitle>{`New Doctor`}</DialogTitle>
      <DialogContent dividers={true}>
        <div className={classes.grid}>
          <TextField
            className={classes.field}
            fullWidth
            error={errors.name}
            helperText={errors.name ? 'Invalid Name' : ''}
            id={'name'}
            label={'Name'}
            name={'name'}
            onChange={(e) => {
              if (errors.name) setErrors({ ...errors, name: false });
              setName(e.target.value);
            }}
            required
            type={'text'}
            value={name}
          />
          <TextField
            className={classes.field}
            fullWidth
            id={'email'}
            label={'Email'}
            name={'email'}
            onChange={(e) => setEmail(e.target.value)}
            required
            type={'email'}
            value={email}
          />
          <TextField
            className={classes.field}
            error={errors.phoneNumber}
            fullWidth
            helperText={errors.phoneNumber ? 'Invalid Phone Number' : ''}
            id={'phone-number'}
            label={'Phone Number'}
            name={'phoneNumber'}
            onChange={(e) => {
              if (errors.phoneNumber) setErrors({ ...errors, phoneNumber: false });
              setPhoneNumber(e.target.value);
            }}
            required
            type={'number'}
            value={phoneNumber}
          />

          <Select
            className={classes.field}
            displayEmpty
            fullWidth
            id={'department'}
            name={'department'}
            onChange={(e) => setDepartment(e.target.value)}
            renderValue={(value) => (value === '' ? 'Department' : value)}
            required
            style={{ marginTop: '1rem' }}
            value={department}
          >
            {departments.map((department, index) => (
              <MenuItem key={index} value={department}>
                {department}
              </MenuItem>
            ))}
          </Select>
        </div>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress style={{ marginBottom: '8px', marginRight: '1rem' }} />
        ) : (
          <>
            <Button color={'secondary'} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button color={'primary'} type={'submit'}>
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </form>
  );
};

DoctorFormModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default DoctorFormModal;
