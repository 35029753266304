import React, { useEffect, useState } from 'react';
import SEO from '../components/common/SEO';
import Layout from '../components/navigation/Layout';
import NotFoundScreen from './not_found_screen';
import LoadingScreen from './loading_screen';
import ReportsDisplay from '../components/common/ReportsDisplay';
import { fetchReports, listenPatientById } from '../services/user_service';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Container, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import WithDropZone from '../components/common/WithDropZone';
import { CloudUpload, Refresh } from '@material-ui/icons';
import { uploadReport } from '../redux/uploads/ducks';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const PatientScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const itemsPerPage = 5;
  const params = new URLSearchParams(props.location.search);
  const page = parseInt(params.get('page')) || 1;
  const patientId = props.match.params.id;

  const [patient, setPatient] = useState(null);

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [hasMore, setHasMore] = useState(false);

  const pages = patient ? (patient.reports ? Math.ceil(patient.reports / itemsPerPage) : 1) : 1;

  useEffect(() => listenPatientById(patientId, setPatient), [patientId]);

  useEffect(() => {
    setReports([]);
    fetchPatientReports();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, patient]);

  const fetchPatientReports = async () => {
    setLoading(true);
    const data = await fetchReports(patientId, itemsPerPage, page);
    if (data.length === 0) {
      params.set('page', (page - 1).toString());
      history.push(`/patients/${patientId}?${params.toString()}`);
    } else {
      // setHasMore(data.length > itemsPerPage);
      setReports(data.slice(0, itemsPerPage));
    }
    setLoading(false);
  };

  const validateFile = (file) => {
    const validTypes = ['application/pdf'];
    return validTypes.indexOf(file.type) !== -1;
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        dispatch(
          uploadReport({
            file: files[i],
            fileId: Math.random().toString(36).slice(2),
            patientId: patient.id,
            progress: 0,
            status: 'uploading',
          })
        );
      }
    }
  };

  const fileUpload = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  if (patient === undefined) {
    return <NotFoundScreen />;
  } else if (patient === null) {
    return <LoadingScreen />;
  } else {
    return (
      <Layout>
        <SEO title={'Patient'} />
        <WithDropZone id={patientId}>
          <Container className={classes.container} maxWidth={'md'}>
            <Typography variant={'h3'}>{patient.name}</Typography>
            <Typography style={{ overflowWrap: 'break-word' }} variant={'body2'}>
              {patient.email}
            </Typography>
            <Typography gutterBottom variant={'body2'}>
              {patient.phoneNumber}
            </Typography>
            <Typography variant={'body2'}>Signed Up — {patient.createdAt}</Typography>
            <Typography style={{ marginBottom: '2rem' }} variant={'body2'}>
              Last Updated — {patient.updatedAt}
            </Typography>
            <Toolbar style={{ padding: 0 }}>
              <Typography style={{ marginRight: 'auto' }} variant={'h1'}>
                REPORTS
              </Typography>
              <IconButton onClick={fetchPatientReports}>
                <Refresh />
              </IconButton>
            </Toolbar>
            {loading ? (
              <CircularProgress style={{ marginTop: '1rem' }} />
            ) : (
              <>
                <Typography variant={'body2'}>
                  Drag and Drop anywhere to upload or
                  <input
                    accept='application/pdf'
                    id='file-upload-button'
                    multiple
                    style={{ display: 'none' }}
                    type='file'
                    onChange={fileUpload}
                  />
                  <label htmlFor='file-upload-button'>
                    <IconButton color={'primary'} aria-label='upload report' component='span'>
                      <CloudUpload />
                    </IconButton>
                  </label>
                </Typography>

                <ReportsDisplay
                  // hasMore={hasMore}
                  page={page}
                  pages={pages}
                  params={new URLSearchParams(props.location.search)}
                  patientId={patientId}
                  reports={reports}
                />
              </>
            )}
          </Container>
        </WithDropZone>
      </Layout>
    );
  }
};

export default PatientScreen;
