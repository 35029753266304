import React, { useEffect, useState } from 'react';
import SEO from '../components/common/SEO';
import Layout from '../components/navigation/Layout';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients, patientsSelector } from '../redux/users/ducks';
import { Add, Refresh, Search } from '@material-ui/icons';
import PatientFormModal from '../components/users/PatientFormModal';
import { useHistory } from 'react-router';

const PatientsScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const {
    data,
    canFetch,
    filters: { phoneNumber: previousPhoneNumber },
    loading,
  } = useSelector(patientsSelector);
  const rowsCount = data.length + (canFetch ? rowsPerPage : 0);

  const setState = () => {
    if (phoneNumber === previousPhoneNumber) {
      if (canFetch) {
        const limit = rowsPerPage * (page + 1) - data.length;
        limit > 0 && dispatch(fetchPatients(true, { phoneNumber }, limit));
      }
    } else {
      dispatch(fetchPatients(false, { phoneNumber }, rowsPerPage));
      setPage(0);
    }
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(setState, [page, data, canFetch, rowsPerPage]);

  const columns = [
    { field: 'name', headerName: 'Name', sortable: true, width: 200 },
    { field: 'email', headerName: 'Email', sortable: true, width: 300 },
    { field: 'phoneNumber', headerName: 'Mobile', sortable: true, width: 175 },
    {
      field: 'view_more',
      headerName: 'View More',
      renderCell: (params) => (
        <Button
          color={'primary'}
          style={{ height: 30, textSize: '1rem', textTransform: 'none' }}
          onClick={() => {
            history.push(`/patients/${params.row.id}`);
          }}
        >
          View
        </Button>
      ),
      sortable: false,
      width: 140,
    },
    { field: 'id', headerName: 'ID', sortable: true, width: 300 },
    { field: 'createdAt', headerName: 'Signed Up', sortable: true, width: 210 },
    { field: 'updatedAt', headerName: 'Updated At', sortable: true, width: 210 },
  ];

  return (
    <Layout>
      <SEO title={'Patients'} />
      <Container maxWidth={'lg'}>
        <div style={{ height: '2rem' }} />
        <TableToolbar
          onSearch={setState}
          phoneNumber={phoneNumber}
          rowsPerPage={rowsPerPage}
          setOpen={setOpen}
          setPhoneNumber={setPhoneNumber}
        />
        <DataGrid
          autoHeight
          columns={columns}
          // disableColumnMenu
          disableSelectionOnClick
          hideFooterPagination
          hideFooterSelectedRowCount
          loading={loading}
          // onRowClick={(params) => history.push(`/patients/${params.row.id}`)}
          onPageChange={(params) => setPage(params.page)}
          onPageSizeChange={(params) => setRowsPerPage(params.pageSize)}
          page={page}
          pageSize={rowsPerPage}
          rows={data}
          rowCount={rowsCount}
          // rowsPerPageOptions={[10, 25, 50, 75, 100]}
          scrollbarSize={70}
        />
      </Container>
      <Dialog open={open} onClose={() => setOpen(false)} scroll={'paper'}>
        <PatientFormModal setOpen={setOpen} />
      </Dialog>
    </Layout>
  );
};

const TableToolbar = ({ onSearch, phoneNumber, rowsPerPage, setOpen, setPhoneNumber }) => {
  const dispatch = useDispatch();

  return (
    <Toolbar style={{ marginBottom: 8, padding: 0 }}>
      <Tooltip title={'Refresh'}>
        <span>
          <IconButton
            aria-label={'refresh'}
            onClick={() => dispatch(fetchPatients(false, { phoneNumber }, rowsPerPage))}
          >
            <Refresh />
          </IconButton>
        </span>
      </Tooltip>

      <Typography style={{ marginRight: 'auto' }} variant={'h3'}>
        Patients
      </Typography>

      <Tooltip title={'Add'}>
        <span>
          <IconButton aria-label={'add'} onClick={() => setOpen(true)}>
            <Add />
          </IconButton>
        </span>
      </Tooltip>
      <TextField
        id={'search-input'}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <IconButton onClick={onSearch}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder={'Phone number...'}
        style={{ margin: '0 1rem', width: '12.5rem' }}
        type={'number'}
        onChange={(e) => setPhoneNumber(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && onSearch()}
        value={phoneNumber}
        variant={'outlined'}
      />
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
};

export default PatientsScreen;
