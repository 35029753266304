import { call, put, select, takeLatest } from 'redux-saga/effects';
import { updateReportProgress, UploadActionTypes } from './ducks';
import { uploadReport } from '../../services/user_service';
import { userSelector } from '../users/ducks';
import store from '../store';
import { setNotification } from '../notifications/ducks';

export function* uploadsSaga() {
  yield takeLatest(UploadActionTypes.UPLOAD_REPORT, uploadReportSaga);
}

function* uploadReportSaga(action) {
  const { upload } = action.payload;
  try {
    const user = yield select(userSelector);
    yield call(uploadReport, user, upload, (fileId, progress) =>
      store.dispatch(updateReportProgress(fileId, progress))
    );
  } catch (e) {
    console.warn(e);
    yield put({ type: UploadActionTypes.UPLOAD_REPORT_FAILED, payload: { fileId: [upload.fileId] } });
    yield put(setNotification('Error uploading report. Please ensure report names are different.', 'error'));
  }
}
