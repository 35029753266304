const defaultNotification = {
  message: '',
  show: false,
  type: 'success',
};

const INITIAL_STATE = {
  notification: defaultNotification,
};

export const NotificationActionTypes = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',

  NOTIFICATION_TIMEOUT: 'NOTIFICATION_TIMEOUT',
  NOTIFICATION_TIMEOUT_SUCCEEDED: 'NOTIFICATION_TIMEOUT_SUCCEEDED',
};

export const setNotification = (message, type) => ({
  type: NotificationActionTypes.SET_NOTIFICATION,
  payload: { message, type: !type ? 'success' : type },
});
export const notificationTimeout = () => ({ type: NotificationActionTypes.NOTIFICATION_TIMEOUT });

export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationActionTypes.SET_NOTIFICATION: {
      const { message, type } = action.payload;
      return { ...state, notification: { message, type, show: true } };
    }

    case NotificationActionTypes.NOTIFICATION_TIMEOUT_SUCCEEDED: {
      return { ...state, notification: { message: '', type: 'success', show: false } };
    }

    default:
      return state;
  }
};

export const notificationSelector = (state) => state.notifications.notification;
