import { combineReducers } from 'redux';
import { notificationsReducer } from './notifications/ducks';
import { usersReducer } from './users/ducks';
import { uploadsReducer } from './uploads/ducks';

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  uploads: uploadsReducer,
  users: usersReducer,
});

export default rootReducer;
