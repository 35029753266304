import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { forgotPassword } from '../services/auth_service';
import SEO from '../components/common/SEO';
import { useDispatch } from 'react-redux';
import { loginUser } from '../redux/users/ducks';
import { Button, Container, Paper, TextField } from '@material-ui/core';
import ResponsiveImage from '../components/common/ResponsiveImage';
import LogoImageSources from '../utils/images/LogoImageSources';
import Notification from '../components/common/Notification';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 12,
    padding: '3rem',
  },
  logo: {
    width: 175,
    minWidth: `175px !important`,
  },
  field: {
    width: 235,
    marginTop: 24,
  },
  button: {
    marginTop: 40,
    width: 235,
  },
  forgotPasswordButton: {
    display: 'block',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 24,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const LoginScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [email, setUserName] = useState('');
  const [isLogin, setIsLogin] = useState(true);

  const handleSignIn = async (e) => {
    e.preventDefault();
    dispatch(loginUser(email, password));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    await forgotPassword(email);
    setIsLogin(true);
  };

  return (
    <Container maxWidth={'xs'} style={{ margin: '4rem auto', width: 'max-content' }}>
      <SEO title={'Login'} />{' '}
      <Paper className={classes.paper} elevation={4}>
        <form
          autoComplete={'on'}
          name={isLogin ? 'Login' : 'Forgot Password'}
          onSubmit={isLogin ? handleSignIn : handleForgotPassword}
          style={{ width: 235 }}
        >
          <div style={{ margin: '0 auto', width: 'fit-content' }}>
            <ResponsiveImage className={classes.logo} image={LogoImageSources} />
          </div>
          <TextField
            autoComplete={'email'}
            className={classes.field}
            id={'email'}
            label={'Email'}
            name={'email'}
            onChange={(e) => setUserName(e.target.value)}
            required
            type={'email'}
            value={email}
          />
          {isLogin && (
            <TextField
              autoComplete={'current-password'}
              className={classes.field}
              id={'password'}
              label={'Password'}
              name={'current-password'}
              onChange={(e) => setPassword(e.target.value)}
              required
              type={'password'}
              value={password}
            />
          )}

          <Button className={classes.button} color={'primary'} type={'submit'} variant={'contained'}>
            {isLogin ? 'Log In' : 'Reset Password'}
          </Button>
          <Button className={classes.forgotPasswordButton} disableRipple onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? 'Forgot Password?' : 'Log In'}
          </Button>
        </form>
      </Paper>
      <Notification />
    </Container>
  );
};

export default LoginScreen;
